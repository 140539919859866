body{
    background-color: rgb(255, 255, 200);
}
h1{
    text-align: center;
}
.body{
    margin-left: 10px;
    height: 1000px;
}
p.content{
    font-size: 10px;
}
div.content{
    font-size: 13px;
}
div.auth{
    font-size: 14px;
}
div.footer{
    text-align: center;
    font-size: 10px;
}
.container{
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    background-color: white;
    box-shadow: 5px 5px 5px #ccc;
}
.index{
    min-height: 400px;
}
.card{
    display: block;
    background-color: white;
    box-shadow: 5px 5px 5px #ccc;
    max-width: 18rem;
    max-height: 18rem;
    margin-left: auto;
    margin-right: auto;
    padding: 3px;
    margin-bottom: 5px;
}
.card a{
    text-decoration: none;
    padding: 2px;
    background-color: teal;
    color: white;
    font-size: 36px;
}
.card small{
    margin-top: auto;
}
.pageTitle {
    color: var(--CX-Secondary-Dark-Grey-0, #4C4C4C);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    padding-bottom: 24px;
    /* 112.5% */
  }
  
  
