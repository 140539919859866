$font-regular: 'CathaySansRegular';
$font-bold: 'CathaySansBold';
$font-light: 'CathaySansLight';

@font-face {
    font-family: $font-light;
    font-weight: normal;
    font-style: normal;
    
    src: url('../../public/font/CathaySans_WCorp_Lt.eot');
    src: url('../../public/font/CathaySans_WCorp_Lt.eot?#iefix') format('embedded-opentype'),
    url('../../public/font/CathaySans_WCorp_Lt.woff2') format('woff2'),
    url('../../public/font/CathaySans_WCorp_Lt.woff') format('woff'),
    url('../../public/font/CathaySans_WCorp_Lt.ttf') format('truetype');
}
  
  @font-face {
    font-family: $font-regular;
    font-weight: normal;
    font-style: normal;
    src: url('../../public/font/CathaySans_WCorp_Rg.eot');
    src: url('../../public/font/CathaySans_WCorp_Rg.eot?#iefix') format('embedded-opentype'),
    url('../../public/font/CathaySans_WCorp_Rg.woff2') format('woff2'),
    url('../../public/font/CathaySans_WCorp_Rg.woff') format('woff'),
    url('../../public/font/CathaySans_WCorp_Rg.ttf') format('truetype');
}

@font-face {
    font-family: $font-bold;
    font-weight: normal;
    font-style: normal;
    src: url('../../public/font/CathaySans_WCorp_Bd.eot');
    src: url('../../public/font/CathaySans_WCorp_Bd.eot?#iefix') format('embedded-opentype'),
    url('../../public/font/CathaySans_WCorp_Bd.woff2') format('woff2'),
    url('../../public/font/CathaySans_WCorp_Bd.woff') format('woff'),
    url('../../public/font/CathaySans_WCorp_Bd.ttf') format('truetype');
}
  

$font-family-base: $font-regular !important;